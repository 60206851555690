/* Font Family */
@import url("https://fonts.cdnfonts.com/css/sf-ui-display");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
/* Universal Css Start Here */
:root {
	--first-color: #61eba7;
	--second-color: #161616;
}
@font-face {
	font-family: "visby-regular";
	src: url("../fonts/VisbyCF-Regular.otf");
}
@font-face {
	font-family: "visby-medium";
	src: url("../fonts/VisbyCF-Medium.otf");
}
@font-face {
	font-family: "visby-semibold";
	src: url("../fonts/visby-semibold.otf");
}
@font-face {
	font-family: "visby-bold";
	src: url("../fonts/VisbyCF-Bold.otf");
}
@font-face {
	font-family: "visby-demibold";
	src: url("../fonts/Fontspring-DEMO-visbycf-demibold.otf");
}
html {
	scroll-behavior: smooth;
}
body {
	font-family: "visby-regular";
}
p {
	font-size: 14px;
}
h1 {
	font-family: "visby-bold";
	font-size: 101px;
	list-style: 111px;
}
h2 {
	font-family: "visby-bold";
	font-size: 55px;
	line-height: 65px;
}
h3 {
    font-family: "visby-bold";
    font-size: 42px;
    line-height: 55px;
}
h4 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
}
h5 {
	font-size: 13px;
	line-height: 23px;
}
/* a.flit-btn {
	background-color: transparent;
	padding: 17px 26px;
	color: #FFF;
	text-decoration: none;
	transition: 0.6s;
	border-radius: 50px;
	font-weight: 800;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
} */
figure {
	margin-bottom: 0;
}
body {
    background-image: url(http://localhost:3000/static/media/body-bg.c52eeb5393995b6d832e.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f2f2f3;
}
/* Universal Css End Here */

/* Header Css Start Here */
#headerTop {
	padding: 20px 0;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 9999;
}
li.active.menu-item  a {
    background: #006464;
    color: #FFF !important;
}
#headerTop .navbar-menu ul.menus {
	padding: 0;
	list-style: none;
	margin: 0;
	text-align: end;
}
#headerTop .navbar-menu ul.menus li {
    display: inline-block;
    margin-left: 5px;
}
section.easy-plan.abcdef .right-before {
    top: 115% !important;
}
#headerTop .navbar-menu ul.menus li a,
#headerTop .navbar-menu ul.menus li button {
    color: #161616;
    font-family: "visby-demibold";
    /* font-weight: 600; */
    text-decoration: none;
    font-size: 14px;
    padding: 7px 12px;
    border-radius: 19px;
}
#headerTop .navbar-menu ul.menus li a.active {
	background: #006464;
	color: #fff;
	padding: 6px 10px;
	border-radius: 30px;
}
#headerTop .navbar-menu ul.menus li a.active {
	background: #006464;
	color: #fff;
	padding: 6px 10px;
	border-radius: 30px;
}
#headerTop .navbar-menu ul.menus li.menu-item.button a,
#headerTop .navbar-menu ul.menus li button {
    border: 1px solid #707070;
    padding: 13px 33px;
    border-radius: 50px;
    transition: 0.6s;
}
#headerTop .navbar-menu ul.menus li.menu-item.button a.signin {
    background-color: #61eba7;
    padding: 13px 49px;
    border: 0px;
}
#headerTop .navbar-menu ul.menus li.menu-item.button a.signin:hover {
	background-color: #ffffff;
}
#headerTop .navbar-menu ul.menus li.menu-item.button a:hover {
	background: #61eba7;
	border-color: #61eba7;
}
/* Header Css Start Here */

/* Banner Css Start Here */
.banner_sec {
	background-repeat: no-repeat;
	padding: 120px 0 60px;
	background-image: url("../img/banner-backgroun.png");
	background-size: 100% 100%;
}
.banner_sec .image-box {
	margin-right: -120px;
	margin-left: -120px;
}
.banner_sec .content-wrapper .title h5 span {
	background: #61eba7;
	padding: 8px 10px;
	border-radius: 0px 50px 50px 0px;
	text-transform: uppercase;
}
.banner_sec .content-wrapper .title h5 {
	margin-bottom: 20px;
}
.banner_sec .content-wrapper .title h2 {
	font-size: 80px;
	color: #161616;
	margin-bottom: 20px;
}
.banner_sec .content-wrapper .title h2 span.green {
	color: #61eba7;
}
.banner_sec .content-wrapper p {
	color: #000000;
	max-width: 69%;
}
.banner_sec .content-wrapper .button-group {
	margin-top: 40px;
}
.banner_sec h5.sub-title span {
	background: #61eba7;
	padding: 7px 10px;
	border-radius: 0px 50px 50px 0px;
	text-transform: uppercase;
}
.banner_sec h2.main-title {
	color: #161616;
	font-size: 75px;
}
.banner_sec h2.main-title span.green {
	color: #61eba7;
}
.banner_sec p {
	margin-top: 15px;
}
.banner_sec .recent-bikes {
	display: flex;
	align-items: center;
	justify-content: end;
	position: absolute;
	bottom: 5%;
	left: 20%;
	right: -20%;
}
@media (min-width: 1440px) {
	.banner_sec .recent-bikes {
		left: 34%;
		right: -34%;
	}
}

@media (max-width: 1440px) {
	.banner_sec .recent-bikes {
		left: 16%;
		right: -16%;
	}
}
@media (max-width: 991px) {
	.banner_sec .recent-bikes {
		left: unset;
		right: 1%;
	}
}
@media (max-width: 1200px) {
	.banner_sec .recent-bikes {
		left: 8%;
		right: -8%;
	}
}
.banner_sec .recent-bikes .bike-box {
	background: #ffffff;
	box-shadow: 0px 0px 7px #00000038;
	border-radius: 15px;
	margin: 0 10px;
	padding: 12px 5px;
	text-align: center;
	transition: 0.8s;
}
.banner_sec .recent-bikes .bike-box:hover {
	opacity: 0.8;
}
.banner_sec .recent-bikes .bike-box a {
	color: #61eba7;
	font-size: 16px;
	font-weight: 700;
	text-decoration: underline;
	text-align: center;
}
.banner_sec .recent-bikes .bike-box figure {
	margin-bottom: 10px;
}
/* Banner Css End Here */

/* Easy Sec Css Start Here */
.easy-plan {
    padding: 60px 0;
    position: relative;
	background-image: url("../img/footer-bg.png");
	background-size: cover;
}
.easy-plan .heading-wrapper {
	text-align: center;
	padding-bottom: 70px;
}
.easy-plan .heading-wrapper h2 {
    color: #161616;
    font-size: 55px;
    line-height: 65px;
    padding-bottom: 10px;
    letter-spacing: -1px;
}
.easy-plan .heading-wrapper h2 span.colored {
	color: #006464;
}
.easy-plan .heading-wrapper p {
	max-width: 55%;
	margin: 0px auto;
	color: #000000;
}
.universal-heading h3.title {
	padding-bottom: 10px;
}
.universal-heading p {
	line-height: 21px;
	margin-bottom: 40px;
}
.universal-heading span.colored {
	color: #006464;
}
.easy-plan .img-box {
	text-align: start;
}
.easy-plan .img-box img.img-fluid {
    max-width: 74%;
    padding-left: 11%;
}
.easy-plan .row-two .img-box {
	text-align: left;
}
.easy-plan .right-before {
	position: absolute;
	right: 0;
	top: 25%;
	width: 35%;
}
.easy-plan .right-before {
	position: absolute;
	right: 0;
	top: 25%;
	width: 35%;
}
/* Easy Sec Css End Here */

/* Process Css Start Here */
.process-sec {
	padding: 60px 0;
	position: relative;
}
.process-sec .universal-heading {
	text-align: center;
	padding-bottom: 50px;
}
.process-box {
	background-color: #ededee;
	padding: 21px 23px;
	border-radius: 16px;
	border: 1px solid #70707036;
}
.process-sec .universal-heading p {
	max-width: 55%;
	margin: 0px auto;
}
.process-sec .process-box .title h3 {
	color: #161616;
	font-size: 30px;
	line-height: 40px;
	margin: 0;
}
.process-sec .process-box .title h4 {
    font-size: 19px;
    font-weight: 600;
    color: #161616;
    font-family: "visby-bold";
    text-shadow: 0 0 black;
}
.process-sec .process-box p {
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    min-height: 175px;
}
section.process-sec .process-box a {
    color: #61EBA7;
    font-size: 14px;
    font-family: "visby-demibold";
    text-decoration: underline;
}
.process-sec .process-box .button-group a {
	color: #61eba7;
	text-decoration: underline;
	font-weight: 600;
}
.process-sec .left-before {
	position: absolute;
	left: 0;
	top: 30%;
	z-index: -9;
}
.process-sec .left-before img {
	width: 80%;
}
/* Process Css Start Here */

/* Shop Css Start Here */
.shop_sec {
	padding: 60px 0;
	position: relative;
}
.shop_sec .universal-heading {
	text-align: center;
}
.shop_sec .universal-heading p {
	max-width: 55%;
	margin: 0px auto;
}
.shop_sec .universal-heading {
	text-align: center;
	padding-bottom: 60px;
}
.shop_sec ul.nav-pills {
	padding-bottom: 20px;
	justify-content: center;
}
.shop_sec ul.nav-pills li.nav-item a.nav-link {
    background: #cbcbcb;
    color: #000;
    border-radius: 50px;
    padding: 10px 25px;
    margin: 0 15px;
    font-family: "visby-demibold";
}
.shop_sec ul.nav-pills li.nav-item a.nav-link.active {
	background: #61eba7;
}
.shop_sec .product-box {
	position: relative;
	margin-bottom: 40px;
}
.shop_sec .product-box .title1 {
	padding-top: 20px;
	display: flex;
	align-items: center;
	text-align: -webkit-match-parent;
	width: 100%;
	justify-content: center;
}
.shop_sec .product-box .title1 h4 {
	font-size: 35px;
	font-family: "visby-bold";
	text-align: center;
	visibility: hidden;
	transition: ease-in 0.5s;
}
.shop_sec .product-box .title1 h3 {
	font-size: 16px;
	font-family: "visby-bold";
	
	position: absolute;
	left: 0;
}
.img-box img {
	cursor: pointer;
}

.shop_sec .product-box .img-box:hover ~ .title1 h4 {
	visibility: visible;
	color: #000000;
	
}
.shop_sec .product-box .title1 h3 a {
	text-decoration: none;
	color: #61eba7;
}
.shop_sec .right-before {
    position: absolute;
    right: 0%;
    top: 50%;
    width: 26%;
}
/* Shop Css Start Here */

/*Benefits Css Start Here */
.benefits {
	padding: 60px 0;
}
.benefits .universal-heading {
	text-align: center;
	margin-bottom: 40px;
}
.benefits .universal-heading p {
	max-width: 70%;
	margin: 10px auto;
}
.benefits .benefits-box {
	background-image: url("../img/benefits.png");
	background-repeat: no-repeat;
	padding: 41px 40px 31px;
	background-size: 100% 100%;
}

.benefits .benefit-dv .txt h3 {
    font-size: 22px;
    line-height: 35px;
    color: #000000;
    letter-spacing: -1px;
}
.benefits .benefit-dv .icon-dv {
	background: #000;
	width: 70px;
	height: 70px;
	text-align: center;
	line-height: 70px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 22px;
}
.benefits .benefit-dv p {
	margin: 0;
}
/*Benefits Css End Here */

/* Reviews Css Start Here */
.client-review {
    padding: 60px 0 122px;
    position: relative;
}
.client-review .universal-heading {
	padding-bottom: 40px;
	text-align: center;
}
.client-review .universal-heading p {
	max-width: 55%;
	margin: 0px auto;
}
.client-review .review-box {
    background: #ededee;
    padding: 20px 22px 68px 22px;
    border-radius: 10px;
    border: 1px solid #70707030;
}
.client-review .review-box .topbar img {
    border: 1px solid #61EBA7;
    border-radius: 50%;
}
.client-review .review-box .topbar {
	display: flex;
	align-items: center;
}
.client-review .review-box .topbar h4.name {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin: 0;
    line-height: 16px;
    font-family: "visby-demibold";
}
.client-review .review-box .topbar p {
	margin: 0;
	font-size: 15px;
	color: #0000009c;
}
.client-review .review-box .topbar figure {
	margin-right: 10px;
}
.client-review .review-box p {
    margin: 20px 0 0 0;
    font-size: 16px;
    font-family: "visby-medium";
}
.client-review .before {
    position: absolute;
    left: -40px;
    top: -11%;
    z-index: -9;
}

.services .client-review .before {
    position: absolute;
    left: 0px;
    top: -53%;
    z-index: -9;
}

.client-review .before img {
	width: 55%;
	z-index: -9;
}
/* Reviews Css End Here */

/* Cta starts here */
.cta-sec {
	background: #61eba7;
	padding: 32px 0px;
}
.cta-sec .cta-content-wrapper h2 {
	font-size: 44px;
}
.cta-sec .social-links-wrapper ul li a i {
    font-size: 24px;
    color: #61eba7;
    background: #000;
    padding: 5px;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cta-sec .social-links-wrapper ul li a  i.fa.fa-reddit-alien {
    font-size: 23px;
    padding-top: 8px;
    padding-left: 5px;
}
.cta-sec .social-links-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: end;
	margin: 0px;
}
.cta-sec .social-links-wrapper ul li {
	margin: 0px 10px;
}
/* Cta ends here */
/* footer css starts here */
footer#footer {
	padding: 100px 0px 80px 0px;
	background-image: url("../img/footer-bg.png");
}
footer#footer p {
	font-size: 16px;
	font-weight: 400;
	margin-top: 30px;
}
footer#footer .footer-heading-wrapper {
	margin-bottom: 29px;
}
footer#footer .footer-menu-wrapper ul {
	padding: 0px;
	list-style: none;
}
footer#footer .footer-menu-wrapper ul li a {
	color: #393939;
}
footer#footer .footer-menu-wrapper ul li {
	line-height: 2.5;
}
footer#footer .footer-heading-wrapper h4 {
	color: #0f151e;
	font-family: "visby-bold";
	font-size: 28px;
}

footer#footer .footer-menu-wrapper ul li a {
    display: flex;
    align-items: start;
}

footer#footer .footer-menu-wrapper ul li a span {
	margin-left: 11px;
	font-size: 15px;
}
footer#footer .footer-menu-wrapper ul li a img {
    position: relative;
    top: 9px;
    max-width: 18px;
}
footer#footer div#copyright {
	text-align: center;
}

footer#footer div#copyright p {
	color: #8a8a8a;
}
/* footer css ends here */

/* a.product-read {
    opacity: 0;
} */

/* .product-box:hover .product-read {
opacity: 1;
}
.product-box {


} */

.product-box:hover .title1 {
    visibility: visible !important;
}

.ti-1 h4 {
    font-size: 24px;
    font-family: 'visby-bold';
}

span.color-read a {
    font-size: 16px;
    color: #61eba7;
}

.ti-1 {
    text-align: center;
}

a.flit-btn {
    background-color: #61EBA7;
    padding: 17px 26px;
    color: #000;
    text-decoration: none;
    transition: 0.6s;
    border-radius: 50px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: inline-block;
}
a.flit-btn span.for-txt {
    position: relative;
    z-index: 1;
	transition: 0.4s all;
}
a.flit-btn:hover span.for-txt {
	color: #FFF;
}
div#exampleModalCenter2 {
    backdrop-filter: blur(19px);
    background: #ffffff00;
}
a.flit-btn span.for-hover {
    width: 100%;
    height: 180%;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 0 150px 150px;
    transition: 0.6s;
    transform: translateY(-150%);
}
.banner_sec .content-wrapper .button-group a.flit-btn {
    overflow: hidden;
}
a.flit-btn:hover span.for-hover {
    transform: unset;
}
/* a.flit-btn::before {
    content: "";
    background: #000;
    display: block;
    position: absolute;
    left: 0px;
    width: 0%;
    height: 100%;
    top: 0px;
    border-radius: 52px;
	transition: 0.6s all;
} */
/* a.flit-btn:hover::before{
	z-index: 0;
	width: 100%;
	height: 100%;
} */