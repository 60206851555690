/* Signin Css Start Here */

figure {
	padding: 0;

	margin-bottom: 0;
}

.footer .footer-text {
	display: none;
}
.signinSec .signInBox .form-group .form-control {
	border: 1px solid #0000002e;
	padding: 25px 20px;
	border-radius: 0px;
	color: #63606070;
}

.signinSec {
	height: 100vh;
	background-image: url("../img/Login-bg.png");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}
.signInBox .nav-tabs {
	justify-content: center;
	border: 0px;
}
.signInBox ul#myTab li.nav-item .nav-link.active {
	border: 0px;
	border-bottom: 3px solid #61eba7;
	font-size: 18px;
	color: #000;
	/* font-family: "visby-regular"; */
}
.signInBox .nav-tabs {
	justify-content: center;
	border: 0px;
	margin-bottom: 21px;
}
.signInBox .nav-tabs li {
	flex: 0 0 50%;
}
.signinSec .img-Dv {
	position: relative;
}

.signinSec .imgBox figure img {
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

.signinSec .LogoBox {
	position: absolute;

	top: 0;

	left: 0;

	right: 0;

	display: flex;

	align-items: center;

	height: 100%;

	justify-content: center;
}

.signinSec .LogoBox figure img {
	width: 100%;
}
.signinSec .logo-wrapper {
    margin-bottom: 16px !important;
    text-align: center;
    margin-top: 22px;
    padding-right: 44px;
}
.form-group {
    margin-bottom: 9px;
}
.signinSec .signInBox {
	padding: 0 30px 0 0px;
	text-align: center;
}
.signinSec .signInBox .title h1 {
    color: #006464 !important;
    font-size: 35px;
}
.signinSec .signInBox .taglines h3 {
	font-family: "trade-bold";
	/* font-weight: 700 !important; */
}
.signinSec .signInBox .taglines h3 strong {
	font-weight: 800;
	font-family: "visby-regular";
	font-size: 25px;
}
.signinSec .signInBox .taglines h3 {
    font-family: "visby-medium";
    font-size: 22px;
    line-height: 1.4;
    font-weight: 400;
    color: #161616;
}
.signinSec .signInBox .taglines h3 strong {
	font-weight: 800;
}
.signinSec .signInBox .taglines p a {
	color: #006464;
	margin-left: 5px;
	font-weight: 700 !important;
	font-family: "visby-bold";
}
.signinSec .signInBox input {
	text-align: center;
}
.signinSec .signInBox .form-group .form-control {
    border: 1px solid #0000002e;
    padding: 8px 20px;
    border-radius: 32px;
    color: #B1B1B2;
    background: transparent;
    height: 50px;
	font-family: "visby-demibold";
}
.signinSec .signInBox .form-group .form-control::placeholder {
	color: #63606070;
}

.signinSec .signInBox button {
	width: 200px;
	font-family: "visby-regular";
	font-weight: 700;
	color: #161616;
	padding: 0.575rem 5.95rem;
	border-radius: 50px;
	transition: 0.7s;
	border: 2px solid #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0px auto;
	margin-right: 0px;
	height: 50px;
	width: 100%;
	background: #61eba7;
}
.signinSec .signInBox button:hover {
	box-shadow: 0px 0px 0px 2px #61eba7;
}

.signinSec .signInBox .actionsLink {
	display: flex;

	align-items: center;

	justify-content: space-between;
}

.signinSec .signInBox .actionsLink .forgotDv a {
	color: #b1b1b2;
}

.signinSec .signInBox .actionsLink .form-group {
	margin-bottom: 0;
}
.signinSec .signInBox .actionsLink label {
	margin-bottom: 0;
	margin-left: 13px;
	color: #b1b1b2;
}
.signinSec .signInBox .privacyDv {
	/* text-align: center;
  
    position: absolute;
  
    bottom: -5rem; */

	left: 0;

	right: 0;
}
.privacyDv p {
	color: #89898a !important;
	font-family: "visby-regular";
	font-weight: 300 !important;
	font-size: 15px !important;
}
.signinSec .signInBox .privacyDv a {
	color: #b5d0bb;
}

@media (max-width: 1250px) {
	.signinSec .signInBox {
		padding: 0 40px 0 40px;
	}
}

@media (max-width: 1100px) {
	.signinSec .signInBox .taglines h3 {
		font-size: 20px;
	}

	.signinSec .LogoBox figure img {
		width: 100%;

		padding: 0 40px;
	}
}

@media (max-width: 768px) {
	.signinSec .img-Dv {
		display: none;
	}

	.signinSec .signInBox {
		padding: 60px 40px 60px 40px;
	}
}

@media (max-width: 450px) {
	.signinSec .signInBox {
		padding: 30px 10px 30px 10px;
	}
}

/* Signin Css End Here */



/* custom checkbox start here */
.custom_checkbox {
    display: block;
    margin-bottom: 15px;
  }
  
  .custom_checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .custom_checkbox label {
    position: relative;
    cursor: pointer;
    font-family: 'visby-medium';
    font-size: 14px;
  }
  
  .custom_checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #61eba7;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius:50%  ;
    -webkit-border-radius:50%  ;
    -moz-border-radius:50%  ;
    -ms-border-radius:50%  ;
    -o-border-radius:50%  ;
    background: #61eba7;
}
  
.custom_checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.signinSec .signInBox .taglines h3 span {
    font-family: "visby-demibold";
}
/* custom checkbox end here */