.terms-para p {
	font-size: 15px;
	color: #1d1d1d;
	font-family: "visby-medium";
}

section.terms-sec {
	padding-bottom: 100px;
	position: relative;
}
.home-and-product p {
	font-size: 16px;
	color: #000000;
	font-family: "visby-regular";
	text-decoration: unset;
	margin-bottom: 70px;
}
.home-and-product p span {
	margin: 5px;
	font-size: 21px;
}
section.terms-sec .right-before {
    position: absolute;
    right: -7%;
    bottom: -82%;
    z-index: -1;
}