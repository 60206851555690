section.applyforloan_page div#headerTop {
    position: relative;
}
.applyloanimg img {
    min-width: 100%;
}

/* custom checkbox start here */
.custom_checkbox {
    display: block;
    margin-bottom: 15px;
  }
  
  .custom_checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  section.contact_form_sec .form_start input::placeholder {
    font-family: "visby-demibold" !important;
    font-size: 14px !important;
    color: #1616165c;
  }
  .custom_checkbox label {
    position: relative;
    cursor: pointer;
    font-family: 'visby-medium';
    font-size: 14px;
  }
  
  .custom_checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #61eba7;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius:50%  ;
    -webkit-border-radius:50%  ;
    -moz-border-radius:50%  ;
    -ms-border-radius:50%  ;
    -o-border-radius:50%  ;
    background: #61eba7;
}
  
  .custom_checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
/* custom checkbox end here */
section.contact_form_sec {
    margin-bottom: 5rem;
}

.modal-apply-loan-text h3 {
  font-size: 20px;
  color: #3B3B3B;
  font-family: 'visby-regular';
  margin: 0;
  line-height: 25px;
  margin-bottom: 6px;
}

.modal-apply-loan-text h2 {
  font-size: 30px;
  color: #006464;
  font-family: 'visby-bold';
  margin: 0;
  line-height: 32px;
}

.modal-apply-loan-text p {
  font-size: 14px;
  color: #000000;
  font-family: 'visby-regular';
}

.two-btns-apply-loan a {
  background-color: #E9E9E9;
  padding: 10px;
  margin: 10px;
  /* width: 100%; */
}

.two-btns-apply-loan button {
  font-size: 14px;
  font-family: 'visby-demibold';
  width: 40%;
  padding: 11px;
  background-color: #1616161f;
  border: unset;
  margin: 10px;
  border-radius: 60px;
  font-weight: 400 !important;
}

.apply-loan .row {
    display: flex;
    align-items: end;
}

.apply-loan {
  position: relative;
  /* top: 250px; */
}
section.contact_form_sec .form_start input.form-control, 
section.contact_form_sec .form_start select.form-control {
  border-radius: 20px;
  padding-left: 18px;
  font-size: 14px !important;
  font-family: "visby-demibold" !important;
  border-color: #C8C8C9;
  background-color: transparent !important;
  padding-bottom: 10px;
  color: #161616 !important ;
}

section.contact_form_sec .form_start  select.form-control {
  background-image: url(../img/background_icon.png);
  background-size: 11px;
  background-repeat: no-repeat;
  appearance: none;
  background-position: center right 15px;
}
section.contact_form_sec .form_start select.form-control {
  padding-top: 16px;
  height: 47px;
  color: #63606070 !important;
}

section.applyforloan_page .right-before {
  position: absolute;
  right: -9%;
}

.modal-dialog.apply-loan.h-100 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.two-btns-apply-loan button.bg-btn {
  background: #61EBA7;
}
div#exampleModalCenter {
  backdrop-filter: blur(19px);
  background: #42424273;
  z-index: 999999;
}
/* section.applyforloan_page section.contact_form_sec .confirm_text_start .confrim_text h4 {
  color: #006464;
  font-size: 26px;
  font-family: 'visby-bold' !important;
}

section.applyforloan_page section.contact_form_sec .confirm_text_start .confrim_text p {
  font-family: 'visby-regular' !important;
  font-size: 14px;
}

section.applyforloan_page section.contact_form_sec .confirm_text_start .confrim_text button:not(.gray) {
  padding: 10px 50px 6px;
}
section.applyforloan_page section.contact_form_sec .confirm_text_start .confrim_text button button.btn.gray {
  padding: 10px 30px 6px !important;
} */


/* Modal CSS Start Here */
/* section.applyforloan_page section.contact_form_sec .confirm_text_start .confrim_text h5 {
  font-size: 21px;
  margin-bottom: 0px;
  font-family: 'visby-regular' !important;
} */
.modal-dialog.apply-loan.h-100 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 576px){
  .applyforloan_page .modal-dialog {
    margin: auto !important;
    width: 43%;
    max-width: 100%;
  }
  
}
div#exampleModalCenter2 {
  backdrop-filter: unset !important;
  background: #ffffffde;
}