/* Begin of Inner Banner Css  */
.inner-banner-head h2 {
    font-size: 70px;
    color: #161616;
    font-family: 'visby-bold';
}
.inner-banner-head h2 span {
    color: #006464;
}
.inner-banner-head p {
    font-size: 16px;
    color: #000000;
    font-family: 'visby-regular';
    line-height: 20px;
    margin: 0;
}
.inner-banner-head {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
/* End of Inner Banner Css / */

/* Begin of Seacrh bar css  */
.search-bar input {
    position: relative;
}
.search-bar input {
    border: 1px solid #000000a1;
    border-radius: 60px;
    padding: 13px 19px 9px;
    background-color: transparent;
}
.search-icon {
    position: absolute;
    top: -1px;
    right: 32px;
}
.search-icon i {
    background-color: #61EBA7;
    padding: 17px;
    border-radius: 50px;
}
.category-pic-text {
    display: flex;
    align-items: center;
}

section.saerch-sec .row {
    display: flex;
    align-items: baseline;
}
.for-flex-right {
    justify-content: right;
}
.for-flex-center {
    justify-content: flex-start;
    padding-left: 18px;
}
span.text-span {
    color: #0000008c;
    font-family: 'visby-bold';
}
.select-category select#cars {
    border: unset;
    color: #1616168c;
    font-family: 'visby-bold';
    outline: none;
}
.select-category {
    padding-left: 10px;
}
/* End of Seacrh bar css  */

/* Begin of Product css  */
.pro-text h3 {
    font-size: 20px;
    color: #161616;
    font-family: 'visby-bold';
    margin: 0;
    font-weight: 800 !important;
    letter-spacing: -1px;
}
.price p {
    font-size: 10px;
    color: #686868;
    font-family: "visby-medium";
    text-transform: uppercase;
    margin: 0;
}
.price h5 {
    font-size: 17px;
    color: #686868;
    font-family: 'visby-bold';
    margin-bottom: 0;
}
.product-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 18px;
}
.price {
    text-align: right;
}
.pro-para p {
    font-size: 14px;
    color: #000000;
    font-family: 'visby-regular';
}
section.saerch-sec .col-md-4 {
    border-right: 1px solid #70707070;
}
.product-btn a {
    font-weight: 100;
    padding: 11px 26px 10px !important;
    font-family: "visby-demibold";
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}

.product-btn a span.for-txt {
    position: relative;
    z-index: 1;
}
.product-btn a:hover span.for-txt {
    color: #FFF;
}
.product-btn a span.for-hover {
    width: 100%;
    height: 197%;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 0 150px 150px;
    transition: 0.6s;
    transform: translateY(-150%);
}
.product-btn a:hover span.for-hover {
    transform: unset;
}







input.abcdefgh.white::before {
    box-shadow: 0px 0px 0px 1px #61eba7;
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: -3px;
    border-radius: 3px;
    margin-top: -4px;
}


.color1 {
    background-color: #000000 !important;
    border-radius: 50px;
}

.color2 {
    background-color: #EEEEEE!important;
    border-radius: 50px;
}

.color3 {
    background-color: #C90000 !important;
    border-radius: 50px;
}
.color4 {
    background-color: #27D9DF !important;
    border-radius: 50px;
}
.button-group.product-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #E8E8E8;
    border-radius: 50px;
    padding-right: 10px;
}
.fill-color {
    width: 9%;
    height: 30px;
}
.color-the {
    display: flex;
    align-items: center;
}

.fo-nam {
    display: flex;
    align-items: flex-start;
}
.fo-nam span p {
    font-size: 15px;
    color: #686868;
    font-family: 'visby-regular';
    font-weight: 700;
    margin: 0;
    padding-right: 8px;
}

.fo-nam input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    content: '';
    margin-top: 0px;
    display: inline-block;
    visibility: visible;
}
.fo-nam input {
    margin-right: 13px;
    margin-top: 4px;
}
input.black::after{
    background-color: #000000;
    border: 1px solid #707070
    ; 
}
input.white::after{
    background-color: #EEEEEE;
    border: 1px solid #707070;
}
input.red::after{
    background-color: #C90000;
    border: 1px solid #707070;
}
input.blue::after{
    background-color: #27D9DF;
    border: 1px solid #707070;
}

.product-box {
    margin: 10px;
}
section.product-sec {
    padding-top: 30px;
    padding-bottom: 160px;
    position: relative;
}
.home-and-product {
    padding-top: 138px;
}
.home-and-product p a {
    font-size: 16px;
    color: #000000;
    font-family: 'visby-regular';
    text-decoration: unset;
}
.load-more-btn button {
    font-size: 14px;
    color: #000000;
    font-family: "visby-demibold";
    background-color: #61eba7;
    border: unset;
    padding: 13px 38px;
    border-radius: 50px;
}
.load-more-btn {
    text-align: center;
    margin-top: 80px;
}
/* section.product-sec .product-box .button-group.product-btn a.flit-btn:hover {
    background: #000;
    color: #FFF;
    transition: 0.6s all;
} */
/* End of Product css  */
section.product-sec .before {
    position: absolute;
    left: 0px;
    bottom: -27px;
}
section.product-sec .right-before {
    position: absolute;
    right: -32px;
    top: -20%;
    width: 35%;
    z-index: -1;
}


section.product-sec .product-box .product-img {
    cursor: pointer;
    text-align: center;
}

