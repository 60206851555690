.ForgotPassword {
	display: flex;
	align-items: center;
}
.ForgotPassword .logo-wrapper {
	margin-bottom: 95px !important;
	text-align: center;
}
.ForgotPassword .signInBox .privacyDv {
	left: 0;
	right: 0;
	position: relative;
	bottom: -86px;
}
.ForgotPassword .signInBox .taglines h3 {
	font-family: "visby-regular";
	font-size: 22px;
	line-height: 1.5;
	color: #161616;
}

section.signinSec.creat_password .logo-wrapper {
    position: absolute;
    top: 9%;
    min-width: 30%;
}
section.signinSec.creat_password .col-lg-4{
	position: unset;
}
section.signinSec.creat_password .privacyDv {
    bottom: 4%;
    position: absolute;
}