.comprofile_Sec .signInBox .form-group .form-control {
    border: 1px solid #989797;
    padding: 8px 20px;
    border-radius: 32px;
    color: #adaeaf;
    height: 50px;
    font-family: 'visby-medium';
    text-align: left;
    background: transparent;
}
.comprofile_Sec {
    height: 100%;
    background-image: url("../img/Login-bg.png");
    background-repeat: no-repeat;
    position: relative;
    padding: 7rem 0;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
}
.comprofile_Sec .img-Dv {
	position: relative;
}
.comprofile_Sec .imgBox figure img {
	width: 100%;
	height: 120vh;
	object-fit: cover;
}
.comprofile_Sec .LogoBox {
	position: absolute;

	top: 0;

	left: 0;

	right: 0;

	display: flex;

	align-items: center;

	height: 100%;

	justify-content: center;
}
.comprofile_Sec .LogoBox figure img {
	width: 100%;
}
.comprofile_Sec .logo-wrapper {
	margin-bottom: 22px;
	text-align: center;
}
.comprofile_Sec .signInBox {
	padding: 0 30px 0 0px;
	text-align: center;
}
.comprofile_Sec .signInBox .title h1 {
	color: #006464 !important;
	font-size: 40px;
}
.comprofile_Sec .signInBox .taglines h3 {
	font-family: "trade-bold";
	font-weight: 700 !important;
}
.comprofile_Sec .signInBox .taglines h3 strong {
	font-weight: 800;
	font-family: "visby-regular";
	font-size: 25px;
}
.comprofile_Sec .signInBox .taglines h3 {
	font-family: "visby-regular";
	font-size: 25px;
	line-height: 1.5;
	color: #161616;
}
.comprofile_Sec .signInBox .taglines h3 strong {
	font-weight: 800;
}
.comprofile_Sec .signInBox .taglines p a {
	color: #006464;
	margin-left: 5px;
	font-weight: 700 !important;
	font-family: "visby-bold";
}
.comprofile_Sec .signInBox input {
	text-align: center;
}
/* .comprofile_Sec .signInBox .form-group .form-control {
	border: 1px solid #0000002e;
	padding: 8px 20px;
	border-radius: 32px;
	color: #63606070;
	height: 50px;
} */
.comprofile_Sec .signInBox .form-group .form-control::placeholder {
	color: #63606070;
}
.comprofile_Sec .signInBox button {
	width: 200px;
	font-family: "visby-regular";
	font-weight: 700;
	color: #161616;
	padding: 0.575rem 5.95rem;
	border-radius: 50px;
	transition: 0.7s;
	border: 2px solid #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0px auto;
	margin-right: 0px;
	height: 50px;
	width: 100%;
	background: #61eba7;
}
.comprofile_Sec .signInBox button:hover {
	box-shadow: 0px 0px 0px 2px #61eba7;
}
.comprofile_Sec .signInBox .actionsLink {
	display: flex;

	align-items: center;

	justify-content: space-between;
}
.comprofile_Sec .signInBox .actionsLink .forgotDv a {
	color: #b1b1b2;
}
.comprofile_Sec .signInBox .actionsLink .form-group {
	margin-bottom: 0;
}
.comprofile_Sec .signInBox .actionsLink label {
	margin-bottom: 0;
	margin-left: 13px;
	color: #b1b1b2;
}
.comprofile_Sec .signInBox .privacyDv {
	/* text-align: center;
  
    position: absolute;
  
    bottom: -5rem; */

	left: 0;

	right: 0;
}
.comprofile_Sec .signInBox .privacyDv a {
	color: #b5d0bb;
}
.comprofile_Sec form h4 {
    font-family: 'visby-bold';
    margin: 1rem 0;
}
label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
 }
 .comprofile_Sec label{
    margin-bottom:0;
 }
 #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
 }
 .comprofile_Sec label.upload_btn {
    color: #61eba7;
    text-decoration: underline;
    font-size: 13px;
    margin-top: 10px;
}
.comprofile_Sec .signInBox button.n-visible {
    background: transparent !important;
    border: 1px solid;
}
.bottom_before {
    position: absolute;
    bottom: -3rem;
    left: 0;
} 
.compprofile_img img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* border: 2px solid #61eba7; */
}
@media (max-width: 1250px) {
	.comprofile_Sec .signInBox {
		padding: 0 40px 0 40px;
	}
}
@media (max-width: 1100px) {
	.comprofile_Sec .signInBox .taglines h3 {
		font-size: 20px;
	}
	.comprofile_Sec .LogoBox figure img {
		width: 100%;

		padding: 0 40px;
	}
}
@media (max-width: 768px) {
	.comprofile_Sec .img-Dv {
		display: none;
	}
	.comprofile_Sec .signInBox {
		padding: 60px 40px 60px 40px;
	}
}
@media (max-width: 450px) {
	.comprofile_Sec .signInBox {
		padding: 30px 10px 30px 10px;
	}
}
section.comprofile_Sec .right-before {
    position: absolute;
    right: -12%;
}
section.comprofile_Sec .signInBox p {
    font-size: 21px;
    font-family: "visby-medium";
}
section.comprofile_Sec .form-group {
    margin-bottom: 19px;
}