.process-text h3 {
    font-size: 45px;
    color: #61EBA7;
    font-family: 'visby-bold';
    text-transform: uppercase;
}

.process-text h2 {
    font-size: 42px;
    color: #161616;
    font-family: 'visby-bold';
    line-height: 35px;
    text-shadow: 0 0 black;
}
.client-review .beforeabcd {
    position: absolute;
    left: 0px;
    top: -458%;
    z-index: -9;
}

.process-text h2 span {
    color: #006464;
}

.get-loan-btn a {
    font-family: "visby-demibold";
    background-color: #61EBA7;
    padding: 14px 30px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.get-loan-btn a span.for-txt {
    position: relative;
    z-index: 1;
}
.get-loan-btn a:hover span.for-txt {
    color: #FFF;
}
.get-loan-btn a span.for-hover {
    width: 100%;
    height: 180%;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 0 150px 150px;
    transition: 0.6s;
    transform: translateY(-150%);
}
.banner_sec .content-wrapper .button-group a {
    overflow: hidden;
}
.get-loan-btn a:hover span.for-hover {
    transform: unset;
}


.get-loan-btn {
    margin-top: 27px;
}

.about-second-row {
    padding: 40px 0px;
}

section.our-process-sec .row {
    display: flex;
    align-items: center;
}
.inner-banner-head p {
    font-size: 14px;
    color: #000000;
    font-family: 'visby-regular';
    line-height: 21px;
    margin: 0;
}
.inner-banner-head h2 {
    font-size: 63px;
    color: #161616;
    font-family: 'visby-bold';
}
.beforexyz {
    position: absolute;
    right: -34%;
    top: 110%;
}