.product-detail-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #6868684d;
    padding-top: 5px;
}
.product-detail-head .product-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 18px; */
}
.product-de-he h3 {
	font-size: 50px;
	color: #191919;
	font-family: "visby-bold";
	margin: 0;
}

.product-price h3 span {
	color: #006464c2;
	font-size: 32px;
	font-family: "visby-bold";
}

.product-de-he p {
    font-size: 16px;
    color: #000000;
    font-family: "visby-medium";
}

.product-description h4 {
    font-size: 20px;
    color: #191919;
    font-family: "visby-bold";
    text-shadow: 0 0 black;
}

.product-description p {
	font-size: 14px;
	color: #000000;
	font-family: "visby-regular";
	margin: 0;
}

.product-description ul li {
	font-size: 15px;
	color: #000000;
	font-family: "visby-regular";
	padding: 0px 20px;
}

.product-description ul {
	padding: 0;
	list-style: none;
}

.product-description ul li::before {
	content: url("../img/check.png");
	position: absolute;
	left: 16px;
}

.fo-colors span p {
    font-size: 18px;
    color: #191919;
    font-family: "visby-bold";
    text-shadow: 0 0 black;
}
.fo-colors {
	display: flex;
	align-items: center;
}

.spanss {
    color: #000000;
    font-family: "visby-bold";
    text-shadow: 0 0 black;
}

.product-description {
	padding-top: 15px;
}

.product-deatil-btn a {
    font-size: 16px;
    color: #161616;
    font-family: "visby-demibold";
    background-color: #61eba7;
    padding: 13px 22px;
    border-radius: 50px;
    text-decoration: unset;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    height: unset;
}
.product-deatil-btn a span.for-txt {
    position: relative;
    z-index: 1;
}
.product-deatil-btn a:hover span.for-txt {
    color: #FFF;
}
.product-deatil-btn a span.for-hover {
    width: 100%;
    height: 197%;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 0 150px 150px;
    transition: 0.6s;
    transform: translateY(-150%);
}
.product-deatil-btn a:hover span.for-hover {
    transform: unset;
}

/* .fo-nam input[type='radio']:checked ~ ::after {
    display: none;
} */




.product-deatil-btn a:hover {
    color: #FFF;
    background: #000;
    transition: 0.6s all;
}
.img-all-car figure {
	background-color: #006464;
	width: 65px;
	height: 62px;
	border-radius: 50px;
	text-align: center;
	padding: 8px;
	margin: 0 auto;
}

.img-all-car {
	text-align: center;
}

.img-car-pic {
	padding-bottom: 60px;
}

.img-all-car h3 {
	margin: 0;
	font-size: 36px;
	font-family: "visby-bold";
}

.img-all-car p {
	font-size: 22px;
	color: #000000;
	font-family: "visby-regular";
	margin: 0;
}

.img-all-car h3 {
	margin: 0;
}

.horwin {
	padding-top: 101px;
}

.ek {
	padding-top: 100px;
}

.pro-img img {
	position: relative;
}

.play-btn {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pro-img {
	padding-bottom: 120px;
}
