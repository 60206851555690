.signInBox .nav-tabs {
    justify-content: center;
    border: 0px;
    margin-bottom: 21px;
    border-bottom: 1px solid #dfdfe0;
}

.signInBox .nav-tabs a.nav-link {
    color: #c8c8c9 !important;
    font-family: "visby-demibold";
}

.signInBox ul#myTab li.nav-item .nav-link.active {
    font-size: 18px;
    color: #000 !important;
    background-color: transparent;
    position: relative;
}

.signInBox ul#myTab li.nav-item .nav-link.active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0px;
    height: 4px;
    background: #61eba7;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.signinSec .signInBox .form-group .form-control {
    height: 43px;
}

.thank-you-wrapper .thank-you-content-wrapper {
    text-align: center;
    margin-top: 30px;
}

.thank-you-wrapper .thank-you-img-wrapper {
    text-align: center;
}

.thank-you-wrapper .thank-you-content-wrapper h3 {
    color: #006464;
    font-size: 30px;
    font-weight: 800;
    line-height: 1.5;
    margin: 0px;
}

.thank-you-wrapper .thank-you-content-wrapper h4 {
    color: #3B3B3B;
    font-size: 22px;
    /* font-weight: 800; */
    line-height: 1.5;
    margin-bottom: 12px;
    font-family: "visby-regular" !important;
}

.thank-you-wrapper .thank-you-content-wrapper h6 {
    color: #000000;
    font-size: 14px;
    /* font-weight: 800; */
    line-height: 1.5;
    margin-bottom: 12px;
    font-family: "visby-regular";
}

div#exampleModal {
    background: #42424273;
    backdrop-filter: blur(19px);
}
.signinSec  .logo-wrapper {
    margin-bottom: 92px !important;
    text-align: center;
}
.signinSec.singin  .logo-wrapper {
    margin-bottom: 17px !important;
    text-align: center;
    /* position: relative; */
    /* top: -77px; */
    /* margin-bottom: 50px; */
}

section.signinSec.login.p-0 .signinSec .logo-wrapper {
    /* margin-bottom: 133px !important; */
    text-align: center;
    position: relative;
    top: -77px;
    margin-bottom: 50px;
}



@media (min-width: 576px) {
    .modal-dialog {
        margin: 17% auto;
    }
}

.signinSec .signInBox .actionsLink label.custom_check_box:checked {
    display: none;
}

.signinSec .signInBox .actionsLink label span {
    display: block;
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #61EBA7;
}

input.custom_check_box:checked~span {
    background: #61EBA7;
}

input.custom_check_box:checked~span::after {
    content: "";
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}