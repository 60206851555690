/* Show Profile starts*/

.Account .profile_section {
	padding-bottom: 6rem;
}

.Account .easy-plan {
	padding-bottom: 0;
}

.Account .ProfileTab {
	background-color: #e9e9eb99;
	/* border: 1px solid lightgrey; */
	border-radius: 9px;
	padding: 2rem 0 3rem 0;
	/* text-align: center; */
	box-shadow: 0 0 3px #0000003d;
}

.Account .ProfileTab .image_div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.Account .ProfileTab .change_profile {
	background: transparent;
	border-radius: 0;
	bottom: -16px;
	color: #e02959;
	font-size: 10px;
	left: 58%;
	top: 61%;
	position: absolute;
	text-align: center;
}

.Account .ProfileTab .hvr_p {
	cursor: pointer;
}

.Account .ProfileTab .bg-img-upload-camera {
	background-image: url("../img/m10.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 30px;
	width: 30px;
	cursor: pointer;
}

.Account .ProfileTab h4 {
	font-weight: 900 !important;
	text-shadow: 0 0 0 black;
	text-align: center;
	margin-top: 0.7rem;
}

.Account .ProfileTab .flx_dividr {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 1.5rem;
	padding-right: 0.5rem;
	cursor: pointer;
}

.Account .ProfileTab .flx_dividr p {
	margin-bottom: 0;
	font-weight: bolder;
}

.Account .ProfileTab .brdr-btm-drk {
	width: 100%;
	border-bottom: 2px solid #d3d3d378;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.Account .ProfileTab .no_active {
	color: #808080b5;
	font-weight: light !important;
}

.Account .InfoDetails {
	background-color: #e9e9eb99;
	/* border: 1px solid lightgrey; */
	border-radius: 9px;
	padding: 2rem 2.5rem;
	/* text-align: center; */
	box-shadow: 0 0 3px #0000003d;
	padding-right: 4.5rem;
	height: 100%;
	max-height: 438px;
}

.Account .InnerDivScrol {
	overflow-y: auto;
	height: 348px;
	overflow-x: hidden;
	padding-right: 0.5rem;
}

.Account .InnerDivScrol::-webkit-scrollbar {
	width: 10px;
	border-radius: 50px;
	/* height: 30px; */
}

/* Track */
.Account .InnerDivScrol::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
}

/* Handle */
.Account .InnerDivScrol::-webkit-scrollbar-thumb {
	background: #61eba7;
	border-radius: 50px;
}

/* Handle on hover */
.Account .InnerDivScrol::-webkit-scrollbar-thumb:hover {
	background: #555;
	border-radius: 50px;
}

.Account .heading_Account {
    font-size: 28px;
    margin-bottom: 25px;
    line-height: 30px;
    letter-spacing: -1px;
}
.Account .subheading_Account {
    font-size: 20px;
    line-height: 22px;
    font-family: "visby-bold";
    letter-spacing: -1px;
}
.Account .edit_account {
    font-size: 15px;
    color: #1473E6;
    text-decoration: underline;
    font-family: "visby-medium";
}
.Account .edit_account:hover {
	text-decoration: #2270b3 underline;
	color: #2270b3;
	cursor: pointer;
}

.Account .field {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 0;
}

.Account .response {
	font-size: 14px;
	margin-bottom: 0;
}

.Account .response_password {
	font-size: 70px;
	line-height: 0;
	color: #777777;
}

/* Show Profile ends*/

/* edit profile starts*/

.Account .inpt_field {
	border-radius: 45px;
	background-color: transparent;
	border: 1px solid #8080808a;
	padding: 0.75rem 2rem;
	width: 100%;
	font-weight: bold;
	font-size: 14px;
}

.Account .inpt_field:focus {
	outline: none;
}

.Account .inpt_field::placeholder {
	color: #80808082;
	font-weight: bold;
}

.Account .bt-prof-green {
	font-size: 14px;
	padding: 0.75rem 1.3rem;
}

/* edit profile ends*/

/* my interest starts*/

.Account .product_img {
	width: 100px;
	height: 80px;
	object-fit: cover;
}

.Account .pad-zada {
	padding: 0.85rem 2.3rem !important;
	/* margin-top: 2rem; */
	position: relative;
	top: 1.5rem;
}

/* my interest ends*/

/* loan page starts */
.Account .white_switch {
	background-color: white;
	width: 250px;
	margin-left: auto;
	padding: 0 0 0 1rem;
	margin-top: 1rem;
	display: flex;
	border-radius: 80px;
	align-items: center;
	justify-content: space-between;
}
.Account .white_switch span {
	font-weight: bold;
}

.Account .loan_status {
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-bottom: 0;
	margin-left: 0.75rem;
	font-weight: 900;
	text-shadow: 0 0 0px black;
}

.Account .Accpeted {
    color: #ffffff;
    /* padding-top: 1rem; */
    margin-bottom: 0;
    margin-left: 0.75rem;
    font-weight: 900;
    text-shadow: 0 0 0px #85ecb7;
    background-color: #006464;
    border-radius: 70px;
    width: 108px;
    text-align: center;
    height: 52px;
    padding: 17px;
}
.Account .Rejected {
	color: #e80d28;
	/* padding-top: 1rem; */
	margin-bottom: 0;
	margin-left: 0.75rem;
	font-weight: 900;
	text-shadow: 0 0 0px #e42423;
	background-color: #ffd5d8;
	border-radius: 70px;
	width: 108px;
	text-align: center;
	height: 52px;
	padding: 17px;
}

.Account .Pending {
	color: #e1da32;
	/* padding-top: 1rem; */
	margin-bottom: 0;
	margin-left: 0.75rem;
	font-weight: 900;
	text-shadow: 0 0 0px #e0ce01;
	background-color: #f8f7d8;
	border-radius: 70px;
	width: 108px;
	text-align: center;
	height: 52px;
	padding: 17px;
}

/* loan page ends here */
.easy-plan .right-before {
    right: -7% !important;
    top: 65% !important;
    z-index: -1;
}
body{
	width: 100%;
	overflow-x: hidden;
}