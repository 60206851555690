.services .chevron_right::before {
  font-weight: light !important;
  /* font-size: 5px!important; */
}

.services .chevron_right {
  font-weight: light !important;
  /* font-size: 5px!important; */
}


.services .serviceh4 {
  font-weight: 900;
}

.services .lear_more {
  color: #6eeca9;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.services .lear_more:hover {
  color: #000;
}

.services .innerservice_loremIpsumHeading{
  font-size: small;
  text-align: left;
}

.services .innerservice_loremIpsumHeading{
  font-size: 42px!important;
  line-height: 52px!important;
  font-weight: 900;
}

.services .innerService_p{
  text-align: left!important;
  max-width: 100%!important;
}

.services .innerservice_loremIpsumHeadingDown{
  font-size: 38px!important;
  line-height: 48px!important;
}

.services .ul_pad_zero {
  padding-left: 0px;
  color: black;
}

.services .ul_tick_round_white {
  background: url('../img/Icon\ awesome-check-circle.png');
  background-repeat: no-repeat;
  background-size: 15px;
  padding-left: 35px;
  list-style: none;
  margin: 0;
  background-position: left;
  margin-top: 8px;
  color: black!important;
}

.services .mt_inner_services{
  margin-top: 6rem;
  margin-bottom: 2rem;
}

/* faq page starts  */

.Faq .faq_content {
  padding-top: 3rem;
  margin-bottom: 5rem;
}

.Faq .acrdn_summry {
  background-color: transparent;
  border-bottom: 1px solid #00000042;
  color: #000;
  padding: 0.5rem 3rem;
  padding-left: 0;
  padding-right: 1rem;
  border-radius: 0px;
}

.Faq .faqs_dash{
  font-size: 18px;
  margin-top: 0.5rem;
  margin-right: 2rem;
  content: "-";
  height: 100%;
}

.Faq .txt-all{
  font-size: 13px;
  padding-right: 4rem;
}

.Faq .mt_inner_faqs_button{
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.Faq .acrdn_details {
  border: 0;
  background-color: transparent;
  padding-left: 0rem;
  padding-top: 1rem;
}

.Faq .acrdn_main {
  border: 0;
  background-color: transparent;
  box-shadow: unset;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent !important;
}

.css-67l5gl::before {
  background-color: transparent !important;
}

.Faq .faq_acrdn_heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}

.Faq .main_faq_head {
  font-size: 42px;
  font-family: "Lato-Bold";
  margin-bottom: 2rem;
  text-shadow: 0 0 1px black;
}

  /* faq page ends  */
h4.serviceh4.my-3 {
  font-family: 'visby-bold';
  font-size: 20px;
  font-weight: 800 !important;
  letter-spacing: -1px;
  color: #000;
  line-height: 26px;
  color: #000;
}
.easy-plan .right-before {
  position: absolute;
  right: 0;
  top: 135%;
  width: 35%;
  bottom: unset;
}
.single_service_detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.services .buttob-group a.flit-btn {
  padding: 12px 54px;
}
.services .bottom_before {
  position: absolute;
  bottom: 0px;
  left: -17px;
}