section.contactus_page div#headerTop {
    position: relative;
}

section.contactus_head span.colored {
    color: #006464;
}

section.contact_form_sec .form_start button.btn {
    background-color: #61eba7;
    padding: 10px 30px 6px;
    color: #000;
    text-decoration: none;
    transition: 0.6s;
    border-radius: 50px;
    font-family: "visby-demibold";
    font-weight: 800;
    width: 100%;
}
section.contactus_page {
    background-image: url("../img/footer-bg.png");
    background-size: cover;
    
}

.contact_desc {
    text-align: center;
}

.contact_desc .contact_img i {
    background: #61eba7;
    padding: 1rem;
    border-radius: 50%;
    font-size: 26px;
    color: #fff;
    margin-bottom: 1rem;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
}

.contact_para p {
    margin: 1rem 0;
    font-size: 14px;
}

.contact_mail a {
    font-size: 14px;
    color: #61eba7;
    font-family: "visby-bold";
}

section.contactus_page .right-before {
    position: absolute;
    right: -7%;
    top: 8%;
    width: 35%;
}

section.contactus_page {
    position: relative;
}

section.contact_desc_sec {
    padding: 5rem 0;
}

section.breadcrums_sec ul {
    list-style: none;
    display: flex;
    gap: 15px;
}

section.breadcrums_sec li {
    font-family: "visby-regular";
    font-size: 12px;
    color: #000;
}

section.breadcrums_sec {
    padding: 3rem 0;
}

section.contactus_head p {
    font-family: "visby-medium";
    font-size: 14px;
    padding: 0 60px;
    margin-bottom: 40px;
}

section.contact_form_sec .form_start input {
    border-radius: 30px;
    border-color: #b0afaf;
    padding: 15px 20px;
    resize: none;
}

section.contact_form_sec .form_start textarea#exampleFormControlTextarea1 {
    border-radius: 15px;
    padding-left: 18px;
    border-color: #b0afaf;
    padding-top: 10px;
    padding-bottom: 20px;
    resize: none;
    background-color: transparent;
}


section.contact_form_sec .form_start textarea#exampleFormControlTextarea1::placeholder {
    font-size: 14px;
    font-family: "visby-demibold";
    color: #b2b2b3;
}
section.contactus_page input::placeholder {
    font-family: "visby-demibold" !important;
    font-size: 15px !important;
}

section.contact_form_sec .form_start input::placeholder {
    font-size: 16px;
    font-family: "visby-demibold";
    color: #b2b2b3;
}

.contact_head h4 {
    font-family: "visby-demibold";
    font-size: 24px;
    text-shadow: 0 0 black;
}

.contact_para p {
    font-family: "visby-regular";
    font-size: 14px;
    line-height: 24px;
}